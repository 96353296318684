import { Link } from 'gatsby' 
import React from 'react'
import styled from 'styled-components'
const StyledDiv = styled.div`
width: 100%;
min-width: 160px;
display: flex;
flex-direction:column;
justify-content:center; 
align-items: center;
transition: all 1s ease;
background:white;

.gridItem{
  width:100%;
  // height:100%;
  position: relative;
  text-align: center;
  color: black;
}
  @media only screen and (max-width: 769px) {
    .gridItem{
      min-height: 500px;
      text-align: top;
      background:blue;
    }
    .content-block{
      top: 0px;
      left: 0px;
      // transform: translate(50%, 50%);
    }
  }
.image-div{
    width: 100%;
    max-width:100%;
  }

  .content-block{
    background: transparent;
    // height: 100%;
    width: 100%;
    max-width:90%;
    max-height:90%;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .offSale{
    text-transform: uppercase;
    letter-spacing: 0.3em;
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 12px;
    text-align: center;
    font-family: "Harmonia Sans", 'Nunito Sans', Arial, sans-serif;
    color: #002500;
  }
  .afterSale{
    font-size: 2.125em;
    font-family: "Harmonia Sans", 'Nunito Sans', Arial, sans-serif;
    font-weight: 700;
    color: #002500;
    margin-bottom: 12px;
  }

  .fasting{
    font-size: 1.25em;
    color: #002500;
    text-align: center;
    margin-bottom: 16px;
    line-height: 1.6;
    font-weight: 400;
    font-family: "Harmonia Sans", 'Nunito Sans', Arial, sans-serif;
  }
  .orderNowButton{
    background: #dd0133 !important;
    border: none !important;
    line-height: 1.42;
    text-decoration: none;
    text-align: center;
    white-space: normal;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    display: inline-block;
    padding: 11px 20px;
    width: auto;
    min-width: 90px;
    cursor: pointer;
  }
  .orderSpan {
    color: #ffffff;
    line-height: 1.42;
    text-align: center;
    white-space: normal;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  @media (max-width: 769px){
    .orderSpan, .orderNowButton, .offSale{
      font-size: 12px;
    }
    .afterSale{
      font-size: 1.25em;
    }
    .fasting{
      font-size: 0.85em;
    }
  }

`
const PromoGrid = () =>{
    return (
        <StyledDiv>
            <div className="gridItem">
                <img className="image-div" src="//cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon_-Winter_Promotion_3024x.png?v=1607710858" data-aspectratio="2.6739427012278307" data-sizes="auto" data-parent-fit="cover" alt=""></img>
                <div className="content-block">
                <div className="offSale">
                        $25 Off ProLon® + 30 Free 
                        <br />Intermittent fasting bars
                </div>
                <div class="afterSale">
                    After Christmas  <span style={{color: "#DD0133"}}> Sale</span>
                </div>

                <div class="fasting">
                    <div class="row" style={{maxWidth: "500px", fontSize: ".9em", margin: "auto auto 0 0px"}}>
                          5-days of ProLon Fasting Mimicking Diet® plus 30 of <strong>The only Intermittent Fasting Bar </strong>- a  full month supply to support your intermittent fasting goals! 
                          </div>
                </div>  
                <Link to="/products/buy-prolon" class="orderNowButton">
                    <span class="orderSpan">Order Now + Free Shipping</span>
                </Link>
                </div>
            </div>

        </StyledDiv>
    )
}

export default PromoGrid